.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
textarea{
  font-family: Verdana;
  border-radius: 3px;
}
.input{
  outline: 2px solid greenyellow;
}

.primary-button{
  border: 2px solid black;
  width: 150px;
  height: 40px;
  font-size: 22px;
  font-weight: 500;
  background-color: #3e68ff;
  color: #ffff;
  border-radius: 8px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.18);
  padding: 0.25em 0.75em;
  min-width: 10ch;
  min-height: 44px;
  transition: 220ms all ease-in-out;
}
.primary-button:hover{
  filter: brightness(80%);
  cursor: pointer;
}

h1{
  font-family: Segoe UI;
  color: ivory;
}
h3{
  font-family: Segoe UI;
  color: ivory;
}

